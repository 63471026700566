$c-primary: #002f6c;
$c-secandary: #d81b2b;
$dots: false;
$body-color-overrided: #333;
$loading-bg-overrided: #002f6c;
$dot-overrided: #d81b2b;
$progress-bar-overided: white;
$progress-bar-incomplete-overided: #000;
$base-font-family: sans-serif;
$font-family-heading: sans-serif;


            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: stretch;
  font-size: 1rem;
  line-height: 1.25;
  font-family: var(--base-font-family);
  &.fill_view_port {
    height: 100%;
  }

  .captured_content {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    width: 100%;
    flex: 1;
    overflow: hidden;
    text-align: center;
    font-weight: 600;
    font-size: var(--heading-font-size) !important;
    img {
      height: 4.75rem; //76
      width: auto;
      margin-bottom: 1.875rem; //30
    }
  }

  .capture_another {
    width: 100%;
    height: 2.8125rem; //45
    border-radius: 0.625rem; //10
    background: transparent;
    border: 1px solid var(--primary);
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    user-select: none;
    font-weight: 500;
    color: black;
    z-index: 2;
  }

  .description {
    text-align: left;
  }

  .option_list {
    margin-top: 1.5rem; //24
    margin-bottom: 1.5rem; //24
  }

  .animation-container {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    width: 100%;
    flex: 1;
    overflow: hidden;
    background-color: var(--page-background-color);
  }
}
