$c-primary: #002f6c;
$c-secandary: #d81b2b;
$dots: false;
$body-color-overrided: #333;
$loading-bg-overrided: #002f6c;
$dot-overrided: #d81b2b;
$progress-bar-overided: white;
$progress-bar-incomplete-overided: #000;
$base-font-family: sans-serif;
$font-family-heading: sans-serif;


            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.wrapper {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: stretch;
  height: 100%;
  font-size: 1rem;
  line-height: 1.375;
  letter-spacing: -0.01875rem; //-0.332143px;



  .description {
    text-align: left;
  }

  .animation-container {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    width: 100%;
    flex: 1;
    overflow: hidden;
    background-color: var(--page-background-color);
  }

  .link_list {
    margin: 0 1.5rem;
  }
  .link {
    text-decoration: none;
    text-align: center;
    font-weight: 600;
    img {
      position: relative;
      width: auto;
      height: 2rem; //32
      margin-top: -0.1875rem; //3
    }
    td {
      text-align: left;
      padding-top: 1rem;
      padding-bottom: 0.5rem;
    }
    .last-of-type {
      padding-bottom: 1.5rem;
    }
  }
  .accepted {
    ul {
      margin-top: 1rem;
      li {
        margin-bottom: 0.5rem;
      }
    }
  }
}
