$c-primary: #002f6c;
$c-secandary: #d81b2b;
$dots: false;
$body-color-overrided: #333;
$loading-bg-overrided: #002f6c;
$dot-overrided: #d81b2b;
$progress-bar-overided: white;
$progress-bar-incomplete-overided: #000;
$base-font-family: sans-serif;
$font-family-heading: sans-serif;


            @import 'src/styles/settings/_variables.scss';
            @import 'src/styles/settings/_functions.scss';
          
.quickAddButtonGroup {
    width: 100%;
    margin-bottom: 1rem;
    display: flex;

    .quickAddButton {
        margin-right: 0.5rem;
    }
}